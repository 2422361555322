<template>
  <div class="container">
    <div class="container content">
      <!-- <v-alert
        text
        class="gepi-alert"
        color="cyan darken-2"
        icon="mdi-alert-circle"
      >
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert> -->
      <div class="project-title-card">
        <div class="box-eigth">
          <div class="project-icon-box">
            <div class="project-icon"></div>
          </div>
        </div>
        <div class="box-half">
          <h1 class="name">{{ project.name }}</h1>
          <v-chip
            v-if="project.priority_area"
            class="ma-2 tag-chip priority-area-chip"
            >{{ project.priority_area }}</v-chip
          >
          <v-chip v-if="project.subgrant" class="ma-2 tag-chip subgrant-chip"
            >Subgrant</v-chip
          >
          <v-chip
            v-if="project.completed_date"
            class="ma-2 tag-chip completed-chip"
            >Completed</v-chip
          >
        </div>
      </div>
      <div class="content-panel associated-pin" id="project-associated-info">
        <div class="pin-title">Associated Pages</div>
        <div class="pin-list">
          <div class="pin-detail">
            <div class="award-tag-box">
              <div class="award-tag"></div>
            </div>
            <div class="pin-text">
              <a
                v-if="project.award && project.award.length > 0"
                :href="`/grants/${project.award[0].award_id}`"
                ><strong>{{ project.award[0].award_name }}</strong></a
              >
            </div>
          </div>
          <div class="pin-detail">
            <div class="program-tag-box">
              <div class="program-tag"></div>
            </div>
            <div class="pin-text">
              <a
                v-if="project.programs && project.programs.length > 0"
                :href="`/programs/${project.programs[0].program}`"
                ><strong>{{ project.programs[0].program_name }}</strong></a
              >
            </div>
          </div>
          <div
            v-if="project.organizations && project.organizations.length > 0"
            class="pin-detail"
          >
            <div class="organization-tag-box">
              <div class="organization-tag"></div>
            </div>
            <div class="pin-text">
              <ul class="project-orgs">
                <li
                  v-for="organization in sortedOrganizations"
                  v-bind:key="organization.organization_name"
                >
                  <a
                    :href="
                      `/contacts/organizations/${organization.organization_id}`
                    "
                    ><strong>{{ organization.organization_name }}</strong></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="project-details">
        <!-- <div class="detail"><strong>Phase</strong>: {{ project.phase[1] }}</div>
                              <div class="detail"><strong>Type</strong>: {{ project.type[1] }}</div> -->
        <div class="detail" v-if="project.start_date">
          <strong>Start Date</strong>:
          {{ project.start_date | formatDate | formatNull_None }}
        </div>
        <div v-if="project.due_date" class="detail">
          <strong>Due Date</strong>:
          {{ project.due_date | formatDate | formatNull_None }}
        </div>
        <div v-if="project.completed_date" class="detail">
          <strong>Completed Date</strong>:
          {{ project.completed_date | formatDate | formatNull_None }}
        </div>
        <div v-if="project.subgrant_amount" class="detail">
          <strong>Subgrant Amount</strong>: ${{
            Number(project.subgrant_amount).toLocaleString()
          }}
        </div>
        <div
          class="detail pt-2"
          style="width: 100%;"
          v-if="project.subgrant_proposed_use"
        >
          <strong>Subgrant Proposed Use</strong>:
          <p class="preserve-newlines">
            {{ project.subgrant_proposed_use }}
          </p>
        </div>
        <div class="detail pt-2" v-if="project.problem_statement">
          <strong>Problem Statement</strong>:
          <span class="preserve-newlines">{{
            project.problem_statement | formatNull_None
          }}</span>
        </div>
        <div class="detail pt-2" style="width: 100%;" v-if="project.summary">
          <strong>Project Summary</strong>:
          <p class="preserve-newlines">
            {{ project.summary | formatNull_None }}
          </p>
        </div>
        <div class="detail pt-2" v-if="filteredOutcomes.length > 0">
          <strong>Outcomes</strong>:
          <template v-if="filteredOutcomes.length > 0">
            <ul v-if="filteredOutcomes.length > 1">
              <li v-for="outcome in filteredOutcomes" :key="outcome">
                {{ outcome }}
              </li>
            </ul>
            <ul v-else class="no-bullets">
              <li>
                {{ filteredOutcomes[0] }}
              </li>
            </ul>
          </template>
          <p v-else>None</p>
        </div>

        <!-- <div class="detail"><strong>Update Frequency</strong>: {{ project.update_frequency[1] }}</div> -->
      </div>
      <div>
        <partner-card :title="`Project Partners`" />
      </div>
      <div>
        <h2 class="pt-8 pb-8">Key Contacts</h2>
        <contacts-table
          :endPoint="
            `contacts/people?ordering=name&project_key_contact=${this.$route.params.id}`
          "
          :keyContactOptionsEndPoint="
            `contacts/people?ordering=name&project=${this.$route.params.id}`
          "
          :columns="['Name', 'Organization', 'Job Title']"
          :projects="[{ project_id: project.id, name: project.name }]"
          :showAddContactsButton="true"
          :showDeleteContactsButton="true"
        />
      </div>
      <div>
        <h2 class="pt-8">Files</h2>
        <file-explorer
          :filesEndPoint="`attachments?project=${this.$route.params.id}`"
          :foldersEndPoint="`folders?project=${this.$route.params.id}`"
        />
      </div>
    </div>
  </div>
</template>
<style>
/* Page CSS */
ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.project-title-card {
  width: -webkit-fill-available;
}

.tabcontent {
  min-height: 500px;
}

h1.name {
  margin-top: 15px;
}
h2 {
  font-family: "roboto-bold";
}
.box-title {
  margin: 0px;
  color: var(--grey);
  margin: 5px 15px;
}
#project-award-box,
#project-program-box {
  display: inherit;
  color: inherit;
  text-decoration: none;
}
#project-award-box:hover,
#project-program-box:hover {
  transform: translateY(-3px);
  webkit-transform: translateY(-3px);
  -webkit-transition: transform 250ms ease;
  transition: transform 250ms ease;
}
.project-item {
  font-size: 15px;
  margin: 10px 15px;
  font-family: "roboto";
  word-wrap: break-word;
}
.project-item.bold {
  font-family: "roboto-bold";
}
.remove-icon {
  padding-top: 5px;
}
.award-icon,
.program-icon,
.organization-icon {
  height: 16px;
  width: 30px;
  margin: 0px auto;
  padding: 0px 10px;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.award-icon {
  background-color: var(--aqua);
  mask-image: url("../assets/icons/awards.svg");
}
.program-icon {
  background-color: var(--aqua);
  mask-image: url("../assets/icons/programs.svg");
}
.organization-icon {
  background-color: var(--aqua);
  mask-image: url("../assets/icons/organizations.svg");
}
.icon-box {
  outline: var(--very-light-grey) solid 1px;
  border-radius: 100px;
  padding: 1px;
  margin-right: 5px;
}
.tablink {
  width: 33%;
}
.update-date {
  font-family: "roboto-bold";
}
.panel-title.update-status {
  padding: 3px;
  border-radius: 5px 5px 0px 0px;
}
#updates .box-container {
  padding: 5px 10px;
}
.content-box.update-box {
  padding: 0px;
  margin: 10px 30px 40px 0px;
  border-radius: 3px;
}
#updates .detail {
  margin: 0px 0px 15px 0px;
}
#updates .update-text {
  color: var(--grey);
  padding: 2px 0px 5px 10px;
}
#add-new-update,
#add-new-testimonial {
  float: right;
  margin-right: 30px;
  position: relative;
  top: -20px;
}

.org-list a {
  display: block;
  margin: 18px 0px;
  padding: 0px 0px 5px 0px;
  font-family: "roboto-bold";
}
.project-details .detail strong {
  color: var(--black);
}

.role-date {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 6px;
  box-sizing: border-box;
  max-width: 500px;
  width: 90%;
  padding: 10px 20px;
  margin: 10px 10px 10px 0px;
  background: #fefefe;
  font-family: "roboto";
  color: var(--grey);
}

.role-button {
  margin: 12px;
}

.add-role-button {
  float: right;
  margin-right: 30px;
}

.add-contact-button {
  float: right;
  margin-right: 30px;
  margin-top: -60px;
}

.info {
  margin-top: 30px;
}

#success-snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: rgba(92, 184, 92, 0.3);
  color: rgba(92, 184, 92);
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
  font-family: "roboto-bold";
}

#success-snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#testimonial-text {
  margin-top: 10px;
}
.testimonial-button {
  width: 80px;
}
.tablink {
  width: 33%;
}
div#project-associated-info {
  float: right;
  width: 30%;
  margin-top: -100px;
}
.project-title-card {
  min-height: 80px;
}
.project-details {
  min-height: 180px;
}
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.success-icon {
  height: 25px;
  width: 25px;
  display: inline-block;
  background-color: rgba(92, 184, 92);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/success.svg");
}

.table-label {
  font-family: "roboto-bold";
  cursor: pointer;
}

.project-icon {
  height: 50px;
  width: 50px;
  margin: 0px auto;
  padding: 55px 10px 10px 10px;
  background-color: white;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/projects.svg");
}
.project-icon-box {
  margin: 15px 10px;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  padding-top: 5px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua);
  position: relative;
}

.associated-pages-panel {
  background-color: var(--very-light-grey);
  border-radius: 6px 6px 0px 0px;
  font-family: "roboto-bold";
  text-align: left;
  font-size: 1.1em;
}

.award-center {
  text-align: center;
}
.associated-pages {
  padding-bottom: 5px;
}
.associated-pages .detail {
  margin: 10px;
}
ul.project-orgs {
  margin: 0px;
}
ul.project-orgs li {
  margin: 5px 0px;
}
.preserve-newlines {
  white-space: pre-line;
}

.no-bullets {
  list-style: none;
  padding-left: 0;
}

.pin-list {
  margin-top: 10px;
  max-height: 220px;
  overflow: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(220, 220, 220, 0.5);
  box-shadow: 0 0 1px rgb(207, 207, 207);
}

/* Responsive formatting */
@media screen and (max-width: 1000px) {
  .box-container.role-box {
    display: block;
  }
  .project-details {
    min-height: 380px;
  }
  div#project-associated-info {
    width: 80%;
    float: unset;
    margin: 30px auto;
  }
}
</style>

<script>
import ContactsTable from "../components/ContactsTable.vue";
import PartnerCard from "../components/PartnerCard.vue";
import FileExplorer from "../components/FileExplorer.vue";

export default {
  title() {
    return "Project Detail: GEPI.io";
  },
  components: { PartnerCard, FileExplorer, ContactsTable },
  data() {
    return {
      project: {},
    };
  },
  created() {
    this.getProject();
  },

  computed: {
    filteredOutcomes() {
      return (this.project?.outcome ?? "")
        .split("\n")
        .filter((outcome) => outcome.trim().length > 0);
    },
    sortedOrganizations() {
      return this.project.organizations
        .slice()
        .sort((a, b) => a.organization_name.localeCompare(b.organization_name));
    },
  },

  methods: {
    getProject: function() {
      this.$api
        .get("projects/" + this.$route.params.id + "/")
        .then((response) => {
          this.project = response.data;
          this.setPageTitle(this.project.name);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            this.$router.push({ name: "404" });
          }
        });
    },
  },
};
</script>
